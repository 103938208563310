import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="basic-breadcrumb-area gray-bg ptb-70">
      <div className="container">
        <div className="basic-breadcrumb text-center">
          <h3 className="">404 Not Found</h3>
          <ol className="breadcrumb text-xs">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">404</li>
          </ol>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
